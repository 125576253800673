import { safeFetchAPI } from '@/api';
import { utils } from '@/helpers';
import { create } from 'zustand';

type RawFilesImporter = {
	status: boolean;
	optinApproved: number;
	files: string[];
	fileAudiences: { [id: string]: string };
	fileMetadata: { [id: string]: RawFileMeta };
};

type RawFileMeta = {
	createdBy: string;
	desc: string;
};

export type ImportHistoryType = {
	name: string;
	nameTimestamp: string;
	description: string;
	created: string;
	createdBy: string;
	processed: boolean;
	pendingDeletion: boolean;
};

type ImportHistoryStoreState = {
	loading: boolean;
	importHistory: ImportHistoryType[];
	importHistoryDetails: Map<string, Record<string, string>[]>;
	optinApproved: number | null;
	rawFilesImporterResponse: Partial<RawFilesImporter>;

	fetchImportHistory: () => void;
	getImportHistoryDetails: (fileName: string) => Promise<Record<string, string>[] | undefined>;
	setOptInApproved: (date?: string) => void;
	setImportHistoryDetails: (id: string, details: Record<string, string>[]) => void;
};

export const useImportHistoryStore = create<ImportHistoryStoreState>((set, get) => ({
	loading: false,
	importHistory: [],
	importHistoryDetails: new Map<string, Record<string, string>[]>(),
	optinApproved: null,
	rawFilesImporterResponse: {},

	async fetchImportHistory() {
		set({ loading: true });
		const [response, error] = await safeFetchAPI<RawFilesImporter>('/importer/:uid/rawfiles');
		set({ loading: false });

		if (error || !response) {
			utils?.toaster?.addToast?.({ message: 'Unable to fetch Import History', type: 'danger', placement: 'top' });
			return null;
		}

		const filesOnDisk = new Set();
		const [files, _] = await safeFetchAPI<string[]>('/users/files/:uid');
		for (const file of files ?? []) {
			filesOnDisk.add(file);
		}

		set({ rawFilesImporterResponse: response });

		const processedResponse = response.files.map((file) => {
			const splitFileName = file.split('_');
			const timestamp = splitFileName[0];

			let fileName = splitFileName.slice(1).join('_');
			fileName = fileName.substring(0, fileName.lastIndexOf('.')) || fileName;

			const { createdBy, desc } = response?.fileMetadata[fileName] || {};

			const processStart = (Number(timestamp) || 0) / 1000 / 1000 / 1000;
			const processed = (utils?.user?.lastCapture || 0) > processStart;

			return {
				name: fileName,
				nameTimestamp: file,
				description: desc,
				created: timestamp,
				createdBy: createdBy,
				processed: processed,
				pendingDeletion: !filesOnDisk.has(file),
			};
		});

		set({ importHistory: processedResponse, optinApproved: response.optinApproved });
	},
	async getImportHistoryDetails(fileName: string) {
		if (!get().importHistoryDetails.get(fileName)) {
			const data: string = await utils.auth.downloadUploadedCSV(fileName, false);

			const split: string[] = data.split('\n');
			const headers = split[0].split(',');
			const rowData = split.slice(1).map((row) => row.split(','));
			const tableRows = rowData.map((row) => {
				const rowObj: Record<string, string> = {};
				headers.forEach((header, index) => {
					rowObj[header] = row[index];
				});

				return rowObj;
			});
			get().importHistoryDetails.set(fileName, tableRows);
		}

		return get().importHistoryDetails.get(fileName);
	},
	setOptInApproved(date?: string) {
		if (date) {
			const timestamp = Math.floor(new Date(date).getTime() / 1000);
			set({ optinApproved: timestamp });
		}
	},
	setImportHistoryDetails(id, details) {
		set({ importHistoryDetails: { ...get().importHistoryDetails, [id]: details } });
	},
}));
